<template>
    <div>
        <h2>{{ $t("invoice.control_properties") }}</h2>
        <div class="box">
            <p>{{ $t("invoice.control_properties_texte") }}</p>
            <CustomTable
                id_table="control_properties"
                :items="properties"
                :busy.sync="table_busy"
                primaryKey="horse_id"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
            />
        </div>
    </div>
</template>


<script type="text/javascript">
    import Invoice from "@/mixins/Invoice.js"

    export default {
        name: "controlProperties",
        mixins: [Invoice],
        data () {
            return {
                start_date: null,
                end_date: null,
                properties: [],
                table_busy: true,
                config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                const now = new Date();
                this.start_date = new Date(now.getFullYear(), now.getMonth() - 11, 1)
                this.end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0)

                this.table_busy = true
                const properties = await this.controlProperties(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
                this.properties = await this.formatAValider(properties)
                this.table_busy = false
            },

            async formatAValider(properties) {
				let p = []
				if(!properties) {
					return []
				}
				let increment = 0

				if(properties.actes) {
					for(let i = 0; i < properties.actes.length; i++) {
						increment ++
						let temp = properties.actes[i]
						temp.increment = increment

						temp.href = "horseFiche"
						temp.params = { horse_id: temp.horse_id }

						p.push(temp)
					}
				}

				if(properties.pension) {
					for(let i = 0; i < properties.pension.length; i++) {
						increment ++
						let temp = properties.pension[i]
						temp.increment = increment

						temp.href = "horseFiche"
						temp.params = { horse_id: temp.horse_id }

						p.push(temp)
					}
				}

				return p
			},
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
